import { CustomTooltip } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import React, { VFC } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

import type {
  ApprovalObjOneOf,
  ApproversGroupedObj,
} from 'api-clients/monolith';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'table-cell',
  },
  wrapperImproved: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    width: theme.spacing(14),
    justifyContent: 'space-between',
  },
  icon: {
    height: '14px',
    width: '14px',
    color: theme.palette.common.gray500,
    verticalAlign: 'middle',
  },
  iconDisabled: {
    color: `${theme.palette.common.gray500} !important`,
  },
  toolTip: {
    marginBottom: theme.spacing(2),
  },
  approvedIcon: {
    color: theme.palette.common.green400,
  },
  rejectedIcon: {
    color: theme.palette.common.red400,
  },
  statusText: {
    marginLeft: theme.spacing(1.25),
    verticalAlign: 'middle',
  },
  right: {
    float: 'right',
  },
}));

interface GroupedStatusProps {
  group?: ApproversGroupedObj;
}

const GroupedStatus: VFC<GroupedStatusProps> = ({ group }) => {
  const styles = useStyles();

  const approved = group?.approved ?? [];
  const pending = group?.pending ?? [];
  const rejected = group?.rejected ?? [];

  return (
    <>
      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approved.join('\n')}
          className={styles.toolTip}
          dense
          placement="top"
        >
          <div className={styles.wrapperImproved}>
            <div>
              <CheckCircleRoundedIcon
                className={classNames(styles.approvedIcon, styles.icon, {
                  [styles.iconDisabled]: approved.length === 0,
                })}
              />
              <Typography variant="caption" className={styles.statusText}>
                <FormattedMessage {...messages.approved} />
              </Typography>
            </div>
            <Typography
              variant="caption"
              className={classNames(styles.statusText, styles.right)}
            >
              {approved.length}
            </Typography>
          </div>
        </CustomTooltip>
      </div>

      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={pending.join('\n')}
          dense
        >
          <div className={styles.wrapperImproved}>
            <div>
              <RemoveCircleRoundedIcon className={styles.icon} />
              <Typography variant="caption" className={styles.statusText}>
                <FormattedMessage {...messages.pending} />
              </Typography>
            </div>
            <Typography
              variant="caption"
              className={classNames(styles.statusText, styles.right)}
            >
              {pending.length}
            </Typography>
          </div>
        </CustomTooltip>
      </div>

      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={rejected.join('\n')}
          dense
        >
          <div className={styles.wrapperImproved}>
            <div>
              <CancelRoundedIcon
                className={classNames(styles.rejectedIcon, styles.icon, {
                  [styles.iconDisabled]: rejected.length === 0,
                })}
              />
              <Typography variant="caption" className={styles.statusText}>
                <FormattedMessage {...messages.rejected} />
              </Typography>
            </div>
            <Typography variant="caption" className={styles.statusText}>
              {rejected.length}
            </Typography>
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};

export interface StatusProps {
  approval: ApprovalObjOneOf;
  group?: ApproversGroupedObj;
}

export const Status: VFC<StatusProps> = ({ approval, group }) => {
  const styles = useStyles();

  if ('grouped_approvers' in approval) {
    return <GroupedStatus group={group} />;
  }

  return (
    <>
      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approval.approved_by.join('\n')}
          className={styles.toolTip}
          dense
          placement="top"
        >
          <div className={styles.wrapper}>
            <CheckCircleRoundedIcon
              className={classNames(styles.approvedIcon, styles.icon)}
            />
            <Typography variant="caption" className={styles.statusText}>
              {approval.approved_count}
            </Typography>
          </div>
        </CustomTooltip>
      </div>

      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approval.pending_by.join('\n')}
          dense
        >
          <div className={styles.wrapper}>
            <RemoveCircleRoundedIcon className={styles.icon} />
            <Typography variant="caption" className={styles.statusText}>
              {approval.pending_count}
            </Typography>
          </div>
        </CustomTooltip>
      </div>

      <div>
        <CustomTooltip
          disableFocusListener
          enterTouchDelay={100}
          title={approval.rejected_by.join('\n')}
          dense
        >
          <div className={styles.wrapper}>
            <CancelRoundedIcon
              className={classNames(styles.rejectedIcon, styles.icon)}
            />
            <Typography variant="caption" className={styles.statusText}>
              {approval.rejected_count}
            </Typography>
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};
