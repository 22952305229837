import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.OpeningApprovals.header',
    defaultMessage: 'Opening Approvals',
  },
  subtitle: {
    id: 'app.containers.OpeningApprovals.subtitle',
    defaultMessage:
      'Opening approvals allow more control over when an opening can become Active by requiring it to be reviewed and approved by an assigned approver group.',
  },
  approverRules: {
    id: 'app.containers.OpeningApprovals.approverRules',
    defaultMessage: 'Approver rules',
  },
  approvals: {
    id: 'app.containers.OpeningApprovals.approvals',
    defaultMessage: 'Approvals',
  },
  autoApprove: {
    id: 'app.containers.OpeningApprovals.autoApprove',
    defaultMessage: 'Auto-approval',
  },
});
