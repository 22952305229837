import { Hidden, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import BackButton from 'components/_shared/BackButton';
import GlobalNav from 'components/GlobalNav';
import { useQueryState } from 'hooks/useQueryState';

import { Approvals } from './Approvals';
import { ApproverGroups } from './ApproverGroups';
import messages from './messages';
import { Settings } from './Settings';
import { useStyles } from './styles';

export type OpeningActionType = 'approved' | 'rejected';
export const OpeningApproval = () => {
  const { accountSlug } = useParams<{ accountSlug: string }>();
  const settingsUrl = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/account/edit`;

  const intl = useIntl();
  const styles = useStyles();

  const [tabIndex, setTabIndex] = useQueryState('tabIndex', 0);

  const [, setSort] = useQueryState('sort', 'asc');
  const [, setPage] = useQueryState('page', 1);
  const [, setPerPage] = useQueryState('perPage', 10);
  const [, setOpeningTitle] = useQueryState('title', '');
  const [openingId, setOpeningId] = useQueryState('openingId', '');
  const [, setOpeningAction] = useQueryState('action');
  const [, setAssignedToMe] = useQueryState(
    'assignedToMe',
    openingId ? 'false' : 'true',
  );
  const [, setStatus] = useQueryState('status', 'Pending');

  const handleWidgetTabChange = (
    event: React.ChangeEvent<unknown>,
    tabIndex: number,
  ) => {
    setTabIndex(tabIndex);
    setPage(null);
    setPerPage(null);
    setSort(null);
    setStatus(null);
    setOpeningTitle(null);
    setOpeningId(null);
    setOpeningAction(null);
    setAssignedToMe(null);
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.header)}</title>
        <meta name="description" content="Opening Approvals" />
      </Helmet>

      <Hidden smDown>
        <GlobalNav />
      </Hidden>

      <div className={styles.approvalContainer}>
        {/* @ts-expect-error BackButton doesn't implement className */}
        <BackButton className={styles.backButton} href={settingsUrl} />

        <div className={styles.headerContainer}>
          <Typography display="inline" variant="h2">
            {intl.formatMessage(messages.header)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage(messages.subtitle)}
          </Typography>
        </div>

        <Tabs
          classes={{ indicator: styles.indicator }}
          onChange={handleWidgetTabChange}
          value={parseInt(tabIndex, 10)}
          variant="standard"
        >
          <Tab
            className={styles.tab}
            label={<span>{intl.formatMessage(messages.approverRules)}</span>}
          />
          <Tab
            className={styles.tab}
            label={<span>{intl.formatMessage(messages.approvals)}</span>}
          />
          <Tab
            className={styles.tab}
            label={<span>{intl.formatMessage(messages.autoApprove)}</span>}
          />
        </Tabs>

        <div className={styles.tabContent}>
          {tabIndex === '0' && <ApproverGroups />}
          {tabIndex === '1' && <Approvals />}
          {tabIndex === '2' && <Settings />}
        </div>
      </div>
    </>
  );
};
